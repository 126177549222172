import { createAction } from '@reduxjs/toolkit';

interface PageOpened {
  page: string;
  isMainPageEnabled: boolean;
  post: {
    categoryId: string;
    _id: string;
    title: string;
    postType: string;
  };
  origin: string;
  category: {
    _id: string;
    slug: string;
  };
}

export const PAGE_OPENED = 'page/PAGE_OPENED';

export const pageOpened = createAction<PageOpened>(PAGE_OPENED);
