import { shouldSearchByHashtag } from '../../api/search/search-utils';
import { extractHashtagsFromText } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';
import { getSearchPageSorting } from '../selectors/sorting-selectors';
import { getSortQueryParams } from '@wix/communities-forum-client-commons/dist/src/constants/sorting';
import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';
import { decodeQuery } from '../services/query-encoding';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { getSearchResultsPerPage } from '../constants/pagination';
import { fetchCounters } from './fetch-counters';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../reducers/root-state';
import { CommonRequest } from '../types';
import { createAction } from '@reduxjs/toolkit';

interface Pagination {
  size: number;
  offset: number;
}

interface FetchSearchSuccess {
  posts: any[];
  users: any[];
  metadata: any;
}

export const FETCH_SEARCH_REQUEST = 'search/FETCH_REQUEST';
export const FETCH_SEARCH_SUCCESS = 'search/FETCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'search/FETCH_FAILURE';
export const FETCH_SEARCH_DEBOUNCED = 'search/FETCH_DEBOUNCED';

export const fetchSearchRequest = createAction(FETCH_SEARCH_REQUEST);
export const fetchSearchSuccess = createAction<FetchSearchSuccess>(FETCH_SEARCH_SUCCESS);
export const fetchSearchFailure = createAction(FETCH_SEARCH_FAILURE);

export default function fetchSearch(query: string, sort: string, pagination: Pagination) {
  return (dispatch: AppDispatch, getState: RootState, { request }: { request: CommonRequest }) => {
    dispatch(fetchSearchRequest());

    const state = getState();
    pagination = pagination || {
      size: getSearchResultsPerPage(getIsMobile(state)),
      offset: 0,
    };
    const sorting = sort || getSearchPageSorting(state);
    const sortingConfig = getSortQueryParams(sorting);

    query = decodeQuery(decodeURIComponent(query));
    const path = shouldSearchByHashtag(query)
      ? // @ts-expect-error
        uri('/search/hashtags').query({
          q: extractHashtagsFromText(query).join(','),
          ...sortingConfig,
          ...pagination,
        })
      : // @ts-expect-error
        uri('/search').query({ q: query, ...sortingConfig, ...pagination });

    const promise = request<FetchSearchSuccess>(path);

    return promise
      .then(results => {
        dispatch(
          fetchSearchSuccess({
            posts: results.posts,
            users: results.users || [],
            metadata: results.metadata,
          }),
        );
        if (results.posts) {
          dispatch(
            fetchCounters({
              postIds: results.posts.map(p => p._id),
              categoryIds: results.posts.map(p => p.categoryId),
            }),
          );
        }
      })
      .catch(() => dispatch(fetchSearchFailure()))
      .then(() => promise);
  };
}
