import { setLocalStorageItem } from './set-local-storage-item';
import {
  getDisplayGuidelinesTypeFromStore,
  getStorageKey,
} from '../selectors/guidelines-selectors';
import { getCategory } from '../../common/selectors/categories-selectors';
import { getForumData } from '../selectors/forum-data-selectors';
import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../reducers/root-state';

interface Guidelines {
  type: string;
}

export const HIDE_GUIDELINES = 'guidelines/HIDE_GUIDELINES';
export const hideGuidelinesSuccess = createAction<Guidelines>(HIDE_GUIDELINES);

export const SHOW_GUIDELINES = 'guidelines/SHOW_GUIDELINES';
export const showGuidelinesSuccess = createAction<Guidelines>(SHOW_GUIDELINES);

const setStorageKey = (value: any, categoryId: string) => (
  dispatch: AppDispatch,
  getState: RootState,
) => {
  const state = getState();
  const category = getCategory(state, categoryId);
  const forumData = getForumData(state);
  const type = getDisplayGuidelinesTypeFromStore(state, categoryId) as string;
  const metaSiteId = getMetaSiteId(state);

  dispatch(value ? hideGuidelinesSuccess({ type }) : showGuidelinesSuccess({ type }));

  dispatch(
    setLocalStorageItem({
      key: getStorageKey(type, metaSiteId, forumData, category),
      value,
    }),
  );
};

export const hideGuidelines = (categoryId: string) => setStorageKey(true, categoryId);
export const showGuidelines = (categoryId: string) => setStorageKey(false, categoryId);
