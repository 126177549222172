import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../reducers/root-state';
import { CommonRequest } from '../types';

export const DELETE_POST_REQUEST = 'post/DELETE_REQUEST';
export const DELETE_POST_SUCCESS = 'post/DELETE_SUCCESS';
export const DELETE_POST_FAILURE = 'post/DELETE_FAILURE';

export const deletePostRequest = createAction(DELETE_POST_REQUEST);
export const deletePostSuccess = createAction<string>(DELETE_POST_SUCCESS);
export const deletePostFailure = createAction(DELETE_POST_FAILURE);

export function deletePost(postId: string) {
  return (dispatch: AppDispatch, getState: RootState, { request }: { request: CommonRequest }) => {
    dispatch(deletePostRequest);

    const promise = request.delete(`/posts/${postId}`);

    return promise
      .then(() => dispatch(deletePostSuccess(postId)))
      .catch(() => dispatch(deletePostFailure()))
      .then(() => promise);
  };
}
