import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { RootState } from '../reducers/root-state';
import { CommonRequest } from '../types';

interface Unsubscribe {
  _id: string;
  type: 'post' | 'category';
}

export const UNSUBSCRIBE_REQUEST = 'unsubscribe/REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'unsubscribe/SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'unsubscribe/FAILURE';

export const unsubscribeRequest = createAction<Unsubscribe>(UNSUBSCRIBE_REQUEST);
export const unsubscribeSuccess = createAction<Unsubscribe>(UNSUBSCRIBE_SUCCESS);
export const unsubscribeFailure = createAction<Unsubscribe>(UNSUBSCRIBE_FAILURE);

export function unsubscribe(entity: Unsubscribe) {
  return (dispatch: AppDispatch, getState: RootState, { request }: { request: CommonRequest }) => {
    dispatch(unsubscribeRequest(entity));

    const promise = request.post(`/subscriptions/unsubscribe`, entity);

    return promise
      .then(() => dispatch(unsubscribeSuccess(entity)))
      .catch(() => dispatch(unsubscribeFailure(entity)))
      .then(() => promise);
  };
}
